<template>
    <div class="wrapper">
        <v-expand-transition>
            <router-view />
        </v-expand-transition>
    </div>
</template>
<script>
import refreshData from '@/mixins/mixins';
import GenericDataService from '@/services/GenericDataService';

export default {
    name:"CampaignOutgoingDetail",
    mixins:[refreshData],
    data() {
        return {
            apiUrl: "/retroplanning/get?id=" + this.$route.params.id,
            mutation: "campaign/setCampaignData",
        }
    },
    mounted() {
        this.refreshData();
    },
    beforeRouteLeave (to, from, next) {
        this.$store.dispatch('base/SET_SUB_MENU', []);
        this.$store.dispatch('base/SET_HEADER_ACTIONS', {});
        next();
    },
    computed: {
        campaign: function() {
            return this.$store.state.campaign.campaign;
        },
    },
    watch: {
        campaign (newVal) {
            this.$store.dispatch('base/SET_SUB_MENU', []);
            
            if(newVal.actions && newVal.actions.get) {
                newVal.actions.get = JSON.parse(JSON.stringify(newVal.actions.get).replaceAll('{{id}}', this.campaign.id));
                newVal.actions.get.forEach(element => {
                    if(element.menus && element.menus[0]){
                        element.menus[0].forEach(elementMenu => {
                            if(elementMenu.check && !newVal.config[elementMenu.check]){
                                elementMenu.disabled = true
                            }
                        });
                    }
                });
                this.$store.dispatch("base/SET_HEADER_ACTIONS", (newVal.actions.get ? newVal.actions.get : {}));
            }
        }
    }
}
</script>
<style lang="scss">
</style>
